import { useState, useEffect } from "react";
import { Table, Flex, Text } from "aplication-yetz";

import { Loader } from "../Loader";
import { useProduto } from "../../Hook/useProduto";
import { Modal } from "../../components/Modal";
import { ModalCategoria } from "./modalCategoria";
import { BoxExclusao } from "../BoxNotificacao";
import { Header } from "./header";
import { filterCategoria } from "../../util/helper";
import { column, Data } from "./types";

import userImg from "../../assets/sistema/editar-vinho.svg";
import excluir from "../../assets/sistema/excluir.svg";

export interface TableCategoria {
    nome: string;
    descricao: string;
    id: number;
}

export function Categorias() {
    const { loadingProduto, getCategoria, categoria, excluirCategoria } =
        useProduto();
    const [modal, setModal] = useState(false);
    const [filter, setFilter] = useState("");
    const [categoriaSelecionada, setCategoriaSelecionada] =
        useState<TableCategoria>({} as TableCategoria);
    const [modalExclusao, setModalExclusao] = useState<boolean>(false);
    const [categoriaId, setCategoriaId] = useState<string | null>(null);

    const arrFilter = categoria ? filterCategoria(categoria, filter) : [];

    const confirmaExclusao = {
        img: userImg,
        // loading: loadingExClusaoCarga,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                excluir esta categoria?
            </Text>
        ),
        width: "500px",
        onClick: () =>
            categoriaId ? handleExcluirCategoria(categoriaId) : null,
        closeModal: () => {
            setModalExclusao(false);
            setCategoriaId(null);
        },
    };

    const data: Data[] = arrFilter.map((user) => {
        const { nome, id, descricao } = user;
        const values = {
            id,
            nome,
            descricao,
        } as TableCategoria;

        return {
            id,
            nome,
            acoes: (
                <Flex width="131px" justifyContent="space-between">
                    <button onClick={() => setCategoriaSelecionada(values)}>
                        <img
                            src={userImg}
                            alt=""
                            className="usuario-icone-user"
                        />
                    </button>
                    <button
                        onClick={() => {
                            setModalExclusao(true);
                            setCategoriaId(id.toString());
                        }}
                    >
                        <img
                            src={excluir}
                            alt=""
                            className="usuario-icone-user"
                        />
                    </button>
                </Flex>
            ),
        };
    });

    const config = {
        title: "Categorias",
        description: "Adicionar categorias",
        onClose: () => {
            setModal(false);
            setCategoriaId(null);
            setCategoriaSelecionada({} as TableCategoria);
        },
        modalActive: modal,
        categoriaSelecionada,
        id: categoriaSelecionada.id,
    };

    async function handleExcluirCategoria(id: string) {
        await excluirCategoria(id);
        setModalExclusao(false);
        setCategoriaId(null);
        fetchData();
    }

    async function fetchData() {
        await getCategoria();
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (Object.values(categoriaSelecionada).length) {
            setModal(true);
        }
    }, [categoriaSelecionada]);

    return (
        <>
            <Header setModal={setModal} setFilter={setFilter} />
            {loadingProduto ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <>
                    <Table data={data} columns={column} pageSize={50} />
                </>
            )}

            <Modal
                component={ModalCategoria}
                active={modal}
                rest={config}
                right
            />

            <Modal
                component={BoxExclusao}
                active={modalExclusao}
                rest={confirmaExclusao}
            />
        </>
    );
}
