import styled, { css } from "styled-components";
import { Flex, Grid } from "aplication-yetz";
import imgFundoCineMarckCombo from "../../assets/sistema/fundo-cineMarckCombo.png";

export const Container = styled.div`
    width: 1062px;
    min-height: calc(100vh - 81px);
    height: auto;
    margin: 0 auto;
    padding-top: 52px;
    display: flex;
    flex-direction: column;
    font-family: "Zen Kaku Gothic New";
    .loader {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .card {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    @media (max-width: 1280px) {
        width: 90%;
    }

    @media (max-width: 1024px) {
    }

    @media (max-width: 480px) {
        padding-top: 31px;
    }
`;

export const StylesIngreso = styled.div`
    background-image: url(${imgFundoCineMarckCombo});
    height: 216px;
    width: 363px;
    padding-top: 23px;
    padding-bottom: 21px;
    .container-itens {
        display: flex;
        align-items: center;

        width: 260px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 9px;

        button {
            img {
                margin-right: 6px;
            }
            &.container-copy {
                position: absolute;
                top: 70%;
                right: -16px;
                transform: translateY(-60%);
                display: flex;
                text-transform: uppercase;
                font-size: 8px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-left: 14px;
                white-space: nowrap;
                text-align: left;
                color: #000;
            }
        }
        &:first-child {
            .container-condigo {
                width: 170px;
            }
            .container-icone {
                transform: translateX(-15.5%);
            }
        }
        &:last-child {
            margin-bottom: 0px;
            &:before {
                display: none;
            }
        }
        &:before {
            content: "";
            display: block;
            width: 237px;
            height: 1px;
            position: absolute;
            bottom: 0px;
            right: 0px;
            background-color: #d9d9d9;
        }
        .container-condigo {
            text-align: center;
            width: 200px;
            h3 {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 13.5569px;
                line-height: 16px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                white-space: nowrap;
                color: #4b4b4b;
                margin-bottom: 6px;
            }

            p {
                font-family: "Roboto Mono";
                font-style: normal;
                font-weight: 700;
                font-size: 19px;
                line-height: 24px;
                color: #000000;
                margin-bottom: 8px;
            }
        }
    }
`;

export const Destaque = styled.div`
    background: #ffe7e7;
    border-radius: 8px;
    height: 54px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    margin-right: 20px;
    span {
        font-weight: 600;
        font-size: 12px;
        line-height: 140%;
        color: #930035;
        margin: 0 20px 0 10px;
    }
    @media (max-width: 480px) {
        margin: 0;
        justify-content: center;
        .seta {
            transform: rotate(90deg);
        }
    }
`;

export const HeaderStyles = styled(Flex)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;

    @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
    }

    @media (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 27px;
        border-bottom: 1px solid #e3e3e3;
        padding-bottom: 63px;
        h3 {
            line-height: normal;
            margin-bottom: 16px;
            text-align: center;
            width: 100%;
        }

        .topoPasso {
            flex-direction: column;
            grid-gap: 16px;
            width: 100%;
            button {
                width: 100%;
                justify-content: center;
            }
            ${Destaque} {
                width: 100%;
            }
        }
    }
`;

export const CardsStyles = styled(Grid)`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    margin-bottom: 78px;

    .container-selo {
        width: 180px;
        margin: 0 auto;
        padding-left: 15px;
        .text-img {
            &:before {
                top: 35%;
            }
        }
    }

    .container-img {
        width: 326px;
        height: 216px;
        background-color: #ffe7e7;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 95%;
        }
    }

    .box-valor {
        height: 216px;
        div[data-text] {
            font-size: ${({ theme }) => theme.fonts.lg};
            font-weight: bold;
        }
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @media (max-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        width: 100%;
        margin-bottom: 39px;
        grid-gap: 0px;
        .container-voucher {
            width: 100%;
            margin-top: 32px;
            ${StylesIngreso} {
                padding-top: 13px;
                width: 100%;
                height: auto;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        .container-selo {
            margin-bottom: 32px;
            display: none;
            span {
            }
        }

        .box-valor {
            height: auto;
            margin-bottom: 21px;
        }

        .container-img {
            width: 283px;
            height: 187px;
            margin: 0 auto;
        }
        .card {
            width: 100%;
            height: 189px;
            margin: 0 auto;
            margin-bottom: 28px;
            display: block;
        }
    }
`;

interface BoxCodigoProps {
    password?: string;
}

const password = css`
    display: flex;
    width: 100%;
    .codigo-vale {
        border-bottom: none !important;
        margin: 0px !important;
        padding: 0 !important;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
`;

export const BoxCodigo = styled.div<BoxCodigoProps>`
    background: #f1f1f1;
    padding: 38px 46px;
    border-radius: 6.77844px;
    ${(props) => (props.password ? password : "")}
    .codigo-vale {
        border-bottom: 0.847305px solid #d9d9d9;
        padding-bottom: 16px;
        margin-bottom: 16px;
    }

    @media (max-width: 480px) {
        padding: 38px 20px;
        span,
        small {
            text-align: center;
        }
    }
`;

export const DetalheStyles = styled(Grid)`
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    grid-gap: 16px;

    .text-img {
        padding-left: 30px;
        &:before {
            left: 9px;
            transform: translate(-50%, -40%);
        }
    }
    .descricao {
        grid-column: span 2;
        .campo-vermelho {
            background: #ffe6e6;
            border-radius: 8px;
            color: #930035;
            display: block;
            padding: 5px 20px;
            width: fit-content;
        }
        .campo-amarelo {
            background: #ffedd7;
            border-radius: 8px;
            color: #a25e0c;
            display: block;
            padding: 5px 20px;
            width: fit-content;
        }
        .campo-verde {
            background: #d7ffe2;
            border-radius: 8px;
            color: #000000;
            display: block;
            padding: 5px 20px;
            width: fit-content;
        }
    }
    .compartilhar {
        margin-top: 70px;
        .btn-pdf {
            width: 207px;
            & > div {
                border-top: none;
            }
        }
        h2 {
            font-weight: 500;
            font-size: 13.5569px;
            line-height: 16px;
            color: #000000;
            margin-bottom: 20px;
        }
        .compWhatsapp {
            background: #28c554;
            border-radius: 4px;
            padding: 10px;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            width: 207px;
            height: 39px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            .seta {
                position: absolute;
                right: 20px;
            }
        }
        .compEmail {
            background: #930035;
            border-radius: 4px;
            padding: 10px;
            display: flex;
            align-items: center;
            grid-gap: 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
            width: 207px;
            height: 39px;
            margin-bottom: 10px;
            cursor: pointer;
            position: relative;
            .seta {
                position: absolute;
                right: 20px;
            }
        }
    }

    @media (max-width: 1280px) {
        margin-bottom: 64px;
    }

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
        .descricao {
            grid-column: unset;
        }
    }

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        padding-bottom: 90px;

        margin: 0 auto;
        .container-detalhe-itens {
            width: 100%;
        }
        .text-img {
            width: 283px;
            margin: 0 auto;
            padding-left: 33px;
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid #e1e1e1;
            &:before {
                top: 10px;
                left: 11px;
            }
        }
        .descricao {
            text-align: center;
            span[class*="campo-"] {
                width: 100%;
                height: 51px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .compartilhar {
            width: 283px;
            margin: 0 auto;
            margin-bottom: 26px;
            h2 {
                margin-bottom: 17px;
            }

            .btn-pdf {
                width: 100%;
            }

            .compWhatsapp {
                width: 100%;
            }

            .compEmail {
                width: 100%;
            }
        }

        .container-ingressos {
            & > div {
                margin: 0 auto;
                background-size: 100%;
                width: 100%;
                margin-bottom: 29px;
            }
        }
    }

    @media (max-width: 375px) {
        .container-ingressos {
            & > div {
                background-size: 100%;
                width: 100%;
            }
        }
    }
`;

export const FooterStyles = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 81px;
    p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #f17e21;
        margin-left: auto;
        text-align: center;
        &.descricao {
            margin-left: 0px;
            margin-right: auto;
            width: 654.85px;
        }
    }

    .container-logo {
        width: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-left: 3px solid #f17e21;
        border-right: 3px solid #f17e21;
        height: 44.5px;
        margin-left: 32px;
        margin-right: 32px;
    }

    @media (max-width: 1366px) {
        padding: 0 2%;
        .container-logo {
            margin: 0px 32px;
            width: 186px;
        }
        p {
            margin: 0px;
            &.descricao {
                margin: 0px;
            }
        }
    }

    @media (max-width: 1024px) {
        flex-direction: row-reverse;
        padding: 0px 5%;
        .container-logo {
            border: none;
        }

        p {
            &.descricao {
                display: none;
            }
        }
    }

    @media (max-width: 480px) {
        .container-logo {
            display: none;
        }
    }
`;

export const ErrorStyles = styled.div`
    .container-title-text {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 63px;
        margin-bottom: 84px;
        .box-text {
            border-bottom: 1px solid #f1f1f1;
            padding-bottom: 48px;
            padding-top: 15px;
        }
        h1 {
            font-size: 48px;
            color: ${({ theme }) => theme.colors.primary};
            line-height: 140%;
        }
    }

    .box-img-e-passo-a-passo {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 63px;
        margin-bottom: 93px;
        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 32px;
        }
    }

    @media (max-width: 1024px) {
        .container-title-text {
            h1 {
                font-size: 36px;
            }
        }
    }

    @media (max-width: 768px) {
        .container-title-text {
            grid-template-columns: 1fr;
            text-align: center;
        }

        .box-img-e-passo-a-passo {
            grid-template-columns: 1fr;
            h3 {
                text-align: center;
            }
        }
    }

    @media (max-width: 480px) {
        .container-title-text {
            grid-gap: 24px;
            margin-bottom: 32px;
            .box-text {
                padding-top: 0;
                padding-bottom: 20px;
            }
            h1 {
                font-size: 24px;
            }
        }

        .box-img-e-passo-a-passo {
            grid-gap: 24px;
            h3 {
                font-size: 24px;
            }
            img {
                &.card-error-img {
                    width: 50%;
                }
            }
        }
    }
`;

export const Modal = styled.div`
    width: fit-content;
    padding: 32px;
    background-color: ${({ theme }) => theme.colors.white};
    ul {
        li {
            list-style: none;
            margin-bottom: 16px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
`;

export const ModalCompartilha = styled.div`
    width: 540px;
    background-color: ${({ theme }) => theme.colors.white};
    .header {
        background: #00a651;
        padding: 25px;
        &.email {
            background: #930035;
        }
        h2 {
            font-weight: 700;
            font-size: 22px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
        }
        img {
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
    .body {
        padding: 32px;
        .campo-telefone {
            display: flex;
            justify-content: center;
            margin: 20px 0;
            img {
                background: #00a651;
                border: 1px solid #00a651;
                box-sizing: border-box;
                border-radius: 5px 0px 0px 5px;
                padding: 12px;
            }
            input {
                border: 1px solid #e0e0e0;
                box-sizing: border-box;
                border-radius: 5px;
                width: 254px;
                padding: 0 20px;
            }
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 140%;
            text-align: center;
            color: #343434;
        }
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            text-align: center;
            color: #707070;
            display: block;
        }
        button {
            background: #00a651;
            border-radius: 4px;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            letter-spacing: 0.42px;
            text-transform: uppercase;
            color: #ffffff;
            margin: 20px auto;
            display: block;
            padding: 10px 20px;
        }
        &.email {
            .campo-telefone {
                img {
                    background: #930035;
                    border: 1px solid #930035;
                }
            }
            button {
                background: #930035;
            }
        }
    }
    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const ListStyles = styled.ul`
    width: 100%;
    li {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        &:hover {
            span {
                background-color: ${({ theme }) => theme.colors.primary};
                color: ${({ theme }) => theme.colors.white};
                transition: all 0.3s ease-in-out;
            }
        }
        &:last-child {
            margin-bottom: 0px;
        }
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 49px;
            height: 49px;
            color: ${({ theme }) => theme.colors.primary};
            border: 1px solid #cfcfcf;
            border-radius: 4px;
            font-weight: bold;
            cursor: default;
            margin-right: 24px;
        }

        p {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            width: 100%;
            color: #4b4b4b;
        }
    }
`;

export const BtnPasso = styled.button`
    background: #930035;
    border-radius: 4px;
    display: flex;
    grid-gap: 10px;
    align-items: center;
    height: 54px;
    padding: 0 20px;
    span {
        font-weight: 500;
        font-size: 13.5569px;
        line-height: 16px;

        color: #ffffff;
    }
`;

export const StylesAlerta = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #f17e21 0%, #fcb614 100%);
    min-height: calc(100vh - 81px);
    .container-text {
        margin-left: 86px;
        width: 473px;
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 140%;
            text-align: center;
            color: #ffffff;
            strong {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 140%;
                color: #941a32;
            }
        }
    }

    @media (max-width: 1366px) {
        .container-img {
            width: 45%;
            img {
                width: 100%;
            }
        }
    }

    @media (max-width: 1024px) {
        padding: 0 5%;
        .container-text {
            p {
                font-size: 18px;
                strong {
                    font-size: 18px;
                }
            }
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        .container-img {
            width: auto;
        }
        .container-text {
            margin: 0px;
        }
    }

    @media (max-width: 480px) {
        .container-text {
            width: 90%;
            margin: 0 auto;
        }
    }
`;
