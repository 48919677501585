import { Login } from "../../components/Login";
import { Footer } from "../../components/Footer";
import { UseAuthenticationProvider } from "../../Hook/useAuthentication";

export function PageLogin() {
    return (
        <UseAuthenticationProvider>
            <Login />
            <Footer />
        </UseAuthenticationProvider>
    );
}
