import { useState } from "react";
import { toast } from "react-toastify";

import { Flex, Text, Box } from "aplication-yetz";

import { Variacao, useProduto, UpdateVariacao } from "../../Hook/useProduto";
import { DetalheProdutoProps } from "./types";
import { Contador } from "../Couner";
import { Toggle } from "../Toggle";
import { formatCurrentMoney, formatCurrentYETZ } from "../../util/helper";

import { Container, ContainerHeader } from "./styles";

const headerDetalheProduto: DetalheProdutoProps[] = [
    { title: "Cod. variação" },
    { title: "valor em reais" },
    { title: "valor em YETZ" },
    { title: "Alerta de estoque" },
    { title: "Aviso estoque" },
    { title: "Disponibilidade" },
];

interface HeaderDetalheProdutoProps {
    itens: Variacao[];
}

export function HeaderDetalheProduto(props: HeaderDetalheProdutoProps) {
    //cor certa -> #F9F9F9
    const { itens } = props;

    return (
        <ContainerHeader>
            <Flex
                height="45px"
                alignItems="center"
                justifyContent="space-around"
            >
                {headerDetalheProduto.map(({ title }) => (
                    <Text key={title}>{title}</Text>
                ))}
            </Flex>

            {itens.map((item) => {
                return (
                    <div className="container-acordion" key={item.id}>
                        <BodyDetalheProduto {...item} />
                    </div>
                );
            })}
        </ContainerHeader>
    );
}

export function BodyDetalheProduto(props: Variacao) {
    const {
        id,
        valor_reais,
        valor_yetz,
        status,
        alerta_estoque,
        status_alerta_estoque,
    } = props;
    const { updateVariacao } = useProduto();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [loadingStatusAlertaEstoque, setLoadingStatusAlertaEstoque] =
        useState<boolean>(false);

    async function updateVariacaoContador(value: UpdateVariacao, id: number) {
        setLoading(true);
        const response = await updateVariacao(value, id);

        if (response.status === 200) {
            setLoading(false);
            return;
        }

        toast.error("Erro ao atualizar o valor da variação");
        setLoading(false);
    }

    async function updateVariacaoStatus(
        value: UpdateVariacao,
        id: number,
        loading: string
    ) {
        if (loading === "loadingStatus") setLoadingStatus(true);
        else setLoadingStatusAlertaEstoque(true);
        const response = await updateVariacao(value, id);

        if (response.status === 200) {
            if (loading === "loadingStatus") setLoadingStatus(false);
            else setLoadingStatusAlertaEstoque(false);
            return;
        }

        toast.error("Erro ao atualizar o valor da variação");
        if (loading === "loadingStatus") setLoadingStatus(false);
        else setLoadingStatusAlertaEstoque(false);
    }

    return (
        <Container>
            <Box width="103px">
                <Text textAlign="center">{id}</Text>
            </Box>

            <Box width="105px">
                <Text textAlign="center">
                    {formatCurrentMoney(parseFloat(valor_reais))}
                </Text>
            </Box>

            <Box width="109px">
                <Text textAlign="center">{formatCurrentYETZ(valor_yetz)}</Text>
            </Box>

            <Box width="134px">
                <Contador
                    loading={loading}
                    initialValue={
                        typeof alerta_estoque === "number"
                            ? alerta_estoque
                            : parseInt(alerta_estoque)
                    }
                    onChange={(value) =>
                        updateVariacaoContador(
                            { alerta_estoque: value.toString() },
                            id
                        )
                    }
                />
            </Box>

            <Box width="115px">
                <Box m="0 auto" width="75px">
                    <Toggle
                        active={status_alerta_estoque ? true : false}
                        loading={loadingStatusAlertaEstoque}
                        onClick={() =>
                            updateVariacaoStatus(
                                {
                                    status_alerta_estoque: status_alerta_estoque
                                        ? 0
                                        : 1,
                                },
                                id,
                                "loadingStatusAlertaEstoque"
                            )
                        }
                    />
                </Box>
            </Box>

            <Box width="115px">
                <Box m="0 auto" width="75px">
                    <Toggle
                        active={status ? true : false}
                        loading={loadingStatus}
                        onClick={() =>
                            updateVariacaoStatus(
                                {
                                    status: status ? 0 : 1,
                                },
                                id,
                                "loadingStatus"
                            )
                        }
                    />
                </Box>
            </Box>
        </Container>
    );
}
