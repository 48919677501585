import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { api } from "../services";

interface UseAuthenticationData {
    loading: boolean;
    error: boolean;
    hasLogin: boolean | null;
    modalConfirm: boolean;
    login: (values: LoginProps) => void;
    logout: () => void;
    genereteAuthCode: () => void;
    confirmAuthCode: (values: string) => void;
    confirmeAuthCode(code: string): Promise<void>;
    generateQRCode(): Promise<string>;
    loadingConfirmCode: boolean;
    authenticator: boolean;
    user: UserAuthenticationData;
}

interface UseAuthenticationProviderProps {
    children: React.ReactChild | React.ReactChild[] | React.ReactNode;
}

export interface LocalStoreProps {
    name: string;
    token: string;
    id: number;
    nivel: string;
    phone_number: string;
}

interface LoginProps {
    document: string;
    password: string;
}

export interface User {
    id: number;
    email: string;
    name: string;
    phone_number: string;
    roles: Roles;
    google_2fa_secret_enabled: number;
}

interface Roles {
    id: number;
    name: string;
    guard_name: string;
}

interface UserAuthenticationData {
    access_token: string;
    expires_in: number;
    role: Array<string>;
    user: User;
}

const UseAuthenticationContext = createContext<UseAuthenticationData>(
    {} as UseAuthenticationData
);

export function UseAuthenticationProvider(
    props: UseAuthenticationProviderProps
) {
    const { children } = props;

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [modalConfirm, setModalConfirm] = useState<boolean>(false);
    const [hasLogin, setHasLogin] = useState<boolean | null>(null);
    const [authenticator, setAuthenticator] = useState<boolean>(false);
    const [user, setUser] = useState<UserAuthenticationData>(
        {} as UserAuthenticationData
    );
    const [tempLocalStore, setTempLocalStore] = useState<LocalStoreProps>(
        {} as LocalStoreProps
    );
    const [loadingConfirmCode, setLoadingConfirmCode] =
        useState<boolean>(false);

    async function login(values: LoginProps) {
        setLoading(true);
        try {
            const { data } = await api.post<UserAuthenticationData>("login", {
                ...values,
            });

            const {
                access_token,
                user: { name, id, phone_number },
                role,
            } = data;
            const [nivel] = role;

            const user: LocalStoreProps = {
                name,
                token: access_token,
                nivel,
                id,
                phone_number,
            };

            await api.post("/usuario/generate-auth-code", null, {
                headers: { Authorization: `Bearer ${access_token}` },
            });

            // localStorage.setItem("user", JSON.stringify(user));
            // history.push("/sistema");
            setUser(data);
            setTempLocalStore(user);
            setModalConfirm(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setError(true);
            setModalConfirm(false);
            toast.error("Email ou senha invalidos");
        }
    }

    async function logout() {
        try {
            await api.post("usuario/logout");
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        }
    }

    async function genereteAuthCode() {
        try {
            await api.post("/usuario/generate-auth-code", null, {
                headers: { Authorization: `Bearer ${tempLocalStore.token}` },
            });
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        }
    }

    async function confirmAuthCode(code: string) {
        setLoadingConfirmCode(true);
        try {
            const { data } = await api.post(
                "/usuario/confirm-auth-code",
                { code },
                {
                    headers: {
                        Authorization: `Bearer ${tempLocalStore.token}`,
                    },
                }
            );

            setModalConfirm(false);

            if (data.success) {
                setHasLogin(true);
                setAuthenticator(true);
                // localStorage.setItem("user", JSON.stringify(tempLocalStore));
                // history.push("/sistema");
            } else {
                setHasLogin(false);
            }
        } catch (error) {
            console.log(error);
            toast.error("ops algo de errado aconteceu");
        }
        setLoadingConfirmCode(false);
    }

    async function generateQRCode() {
        try {
            const response = await api.post<string>(
                "usuario/google-2fa-generate-qrcode",
                {},
                {
                    headers: {
                        Authorization: `Bearer ${tempLocalStore.token}`,
                    },
                }
            );

            return response.data;
        } catch (error) {
            console.log(error);
            return "";
        }
    }

    async function confirmeAuthCode(code: string) {
        try {
            const response = await api.post(
                "usuario/google-2fa-confirm-secret",
                { secret: code },
                {
                    headers: {
                        Authorization: `Bearer ${tempLocalStore.token}`,
                    },
                }
            );

            if (response.data.success) {
                localStorage.setItem("user", JSON.stringify(tempLocalStore));
                window.location.href = "/produtos";
                return;
            }

            toast.error("Código inválido");
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <UseAuthenticationContext.Provider
            value={{
                error,
                user,
                generateQRCode,
                confirmeAuthCode,
                loading,
                authenticator,
                modalConfirm,
                logout,
                login,
                hasLogin,
                genereteAuthCode,
                confirmAuthCode,
                loadingConfirmCode,
            }}
        >
            {children}
        </UseAuthenticationContext.Provider>
    );
}

export function useAuthentication() {
    const context = useContext(UseAuthenticationContext);
    return context;
}
