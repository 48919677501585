import { Flex, TextImg, Box, Filter, ButtonSystem } from "aplication-yetz";

import imgEditar from "../../assets/sistema/card-editar.svg";

interface SubHeaderProps {
    setFiltro: (filtro: string) => void;
    setModalVariacao: (active: boolean) => void;
}

export function SubHeader(props: SubHeaderProps) {
    const { setFiltro, setModalVariacao } = props;

    return (
        <Flex justifyContent="space-between" mb="64px">
            <Box>
                <TextImg fontSize="mdl" img={imgEditar} bold={true} pl="24px">
                    Variações
                </TextImg>
            </Box>

            <Filter onChange={(e) => setFiltro(e.target.value)} />

            <ButtonSystem onClick={() => setModalVariacao(true)}>
                Adicionar variação
            </ButtonSystem>
        </Flex>
    );
}
