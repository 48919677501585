import { ButtonSystem } from "aplication-yetz";
import { useFornecedor } from "../../../Hook/useFornecedor";
import { Container } from "./styles";
export function Header() {
    const { setopenModalAdd } = useFornecedor();
    return (
        <Container>
            <h1>Fornecedor</h1>
            <ButtonSystem onClick={() => setopenModalAdd(true)}>
                Novo Fornecedor
            </ButtonSystem>
        </Container>
    );
}
