import { useEffect, useState } from "react";
import { Loader } from "../Loader";
import { Table, Flex } from "aplication-yetz";
import { useUser } from "../../Hook/useUser";
import { filterUser, phoneNumber } from "../../util/helper";
import { Header } from "./header";
import { column } from "./config";
import { Data, Usuario } from "./types";

import { ContainerTable } from "../tables/styles";
import { Toggle } from "../Toggle";

import { Modal } from "../../components/Modal";
import { User } from "./user";

import userImg from "../../assets/sistema/editar-user.svg";

export function Usuarios() {
    const { users, loading } = useUser();
    const [user, setUser] = useState<Usuario>({} as Usuario);
    const [modal, setModal] = useState<boolean>(false);
    const [filtro, setFiltro] = useState<string>("");

    const config = {
        onClose: closeModal,
        user,
        modalActive: modal,
    };

    const arrFilter = filterUser(filtro, users);

    const data: Data[] = arrFilter.map((user) => {
        const {
            name,
            email,
            phone_number,
            roles,
            status,
            id,
            last_login: ultimo_acesso,
            document,
        } = user;
        const [role] = roles;
        const { name: roleName } = role;
        const values = {
            name,
            email,
            phone_number,
            password: "",
            password_confirmation: "",
            role: roleName,
            status,
            id,
            document,
        } as Usuario;

        return {
            nome: name,
            email,
            celular: phoneNumber(phone_number),
            login: email,
            ultimo_acesso: ultimo_acesso
                ? new Intl.DateTimeFormat("pt-BR").format(
                      new Date(ultimo_acesso)
                  )
                : "",
            perfil: roleName,
            acoes: (
                <Flex width="131px" justifyContent="space-between">
                    <button onClick={() => setUser(values)}>
                        <img
                            src={userImg}
                            alt=""
                            className="usuario-icone-user"
                        />
                    </button>
                    <Toggle active={status ? true : false} />
                </Flex>
            ),
        };
    });

    function closeModal() {
        setModal(false);
        setUser({} as Usuario);
    }

    useEffect(() => {
        if (Object.values(user).length) {
            setModal(true);
        }
    }, [user]);

    return (
        <>
            <Header setModal={setModal} setFiltro={setFiltro} />

            {loading ? (
                <div className="container-loading">
                    <Loader />
                </div>
            ) : (
                <ContainerTable>
                    <Table data={data} columns={column} />
                </ContainerTable>
            )}

            <Modal component={User} active={modal} rest={config} right={true} />
        </>
    );
}
