import { Text } from "aplication-yetz";
import { useState } from "react";

import { useHistory } from "react-router-dom";
import {
    useDetalheEstoque,
    HistoryProps,
    Extrair,
} from "../../Hook/useDetalheEstoque";

import { Loader } from "../../components/Loader";

import { Modal } from "../../components/Modal";
import { BoxExclusao } from "../../components/BoxNotificacao";

import { Calendario } from "./calendario";
import { ModalFiltro } from "./modalFiltro";

import { Header, SubHeader } from "./header";
import { Cards } from "./cards";
import { TableDetalheEstoque } from "./table";

import imgAlerta from "../../assets/sistema/alerta.svg";
import { Container, LoaderStyles } from "./styles";

export interface DadosModal {
    data: string;
    variacao_id: number;
    carga_id: number;
    onClose?: () => void;
}

export interface ModalFiltroDados {
    user?: string;
    data?: string;
}

export function EstoqueDetalhe() {
    const history = useHistory<HistoryProps>();
    const { detalheEstoque, extrair } = useDetalheEstoque();
    const [filtro, setFiltro] = useState<string>("");
    const [modal, setModal] = useState<boolean>(false);
    const [modalFiltro, setModalFiltro] = useState<boolean>(false);

    const [idsExtracao, setIdsExtracao] = useState<Extrair | null>(null);
    const [loadingExtracao, setLoadingExtracao] = useState<boolean>(false);
    const [modalExtrair, setModalExtrair] = useState<boolean>(false);

    const [dadosModal, setDadosModal] = useState<DadosModal>();
    const [modalFiltroDados, setModalFiltroDados] = useState<ModalFiltroDados>(
        {}
    );

    const { variacao } = detalheEstoque;

    const header = {
        estoquer_atual: variacao?.total_disponivel,
        estoque_minimo: variacao?.alerta_estoque,
        alerta_estoque: variacao?.status_alerta_estoque,
        nome: variacao?.nome,
        valor: variacao?.valor_reais,
        id: variacao?.id,
    };

    if (!history.location.state?.id) {
        history.goBack();
        return <></>;
    }

    if (Object.values(detalheEstoque).length === 0) {
        return (
            <LoaderStyles>
                <Loader />
            </LoaderStyles>
        );
    }

    const configModalData = {
        ...dadosModal,
        onClose: () => setModal(false),
    };

    const configModalFiltro = {
        setModalFiltroDados: (dados: ModalFiltroDados) =>
            setModalFiltroDados(dados),
        modalFiltroDados: modalFiltroDados,
        onClose: () => setModalFiltro(false),
    };

    const confirmExtrair = {
        img: imgAlerta,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                extrair os dados?
            </Text>
        ),
        width: "500px",
        onClick: () => handleExtrair(),
        closeModal: () => setModalExtrair(false),
        loading: loadingExtracao,
    };

    async function handleExtrair() {
        setLoadingExtracao(true);

        if (idsExtracao) {
            await extrair(idsExtracao);
        }

        setIdsExtracao(null);
        setModalExtrair(false);
        setLoadingExtracao(false);
    }

    return (
        <Container>
            <Header {...header} />
            <Cards {...detalheEstoque} />
            <SubHeader setFiltro={setFiltro} setModalFiltro={setModalFiltro} />
            <TableDetalheEstoque
                filtro={filtro}
                setModal={setModal}
                setDadosModal={setDadosModal}
                setIdsExtracao={setIdsExtracao}
                setModalExtrair={setModalExtrair}
                modalFiltroDados={modalFiltroDados}
            />
            <Modal
                component={Calendario}
                active={modal}
                right={true}
                rest={configModalData}
            />

            <Modal
                component={ModalFiltro}
                active={modalFiltro}
                right={true}
                rest={configModalFiltro}
            />

            <Modal
                component={BoxExclusao}
                active={modalExtrair}
                rest={confirmExtrair}
            />
        </Container>
    );
}
