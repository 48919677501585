import { useEffect, useState } from "react";
import { Flex } from "aplication-yetz";
import { usePlataforma } from "../../Hook/usePlataforma";
import { Goback } from "../../components/Goback";
import { Toggle } from "../../components/Toggle";

import { HeaderStyles } from "./styles";
import { toast } from "react-toastify";

interface HeaderProps {
    estoque: boolean;
    status: boolean;
    catalogosIds: number[];
}

export function Header(props: HeaderProps) {
    const { estoque, status, catalogosIds } = props;
    const { updateCatalogo } = usePlataforma();

    const [initialEstoque, setInitialEstoque] = useState(estoque);
    const [loadingEstoque, setLoadingEstoque] = useState(false);

    const [initalStatus, setInitialStatus] = useState(status);
    const [loadingStatus, setLoadingStatus] = useState(false);

    async function handleEstoque(estoque: boolean) {
        setLoadingEstoque(true);

        const config = {
            "sem-estoque": estoque ? 1 : 0,
            status: status ? 1 : 0,
            catalogo: catalogosIds,
        };

        const response = await updateCatalogo(config);

        if (response.status === 200) {
            toast.success("Estoque Alterado com sucesso!");
        }

        setLoadingEstoque(false);
    }

    async function handleStatus(status: boolean) {
        setLoadingStatus(true);
        const config = {
            status: status ? 1 : 0,
            catalogo: catalogosIds,
        };

        const response = await updateCatalogo(config);

        if (response.status === 200) {
            toast.success("Status alterado com sucesso");
        }

        setLoadingStatus(false);
    }

    useEffect(() => {
        setInitialEstoque(estoque);
        setInitialStatus(status);
    }, [estoque, status]);

    return (
        <HeaderStyles justifyContent="space-between" alignItems="center">
            <Goback title="Detalhes Plataforma" />

            <Flex width="347px" justifyContent="space-between">
                <Toggle
                    loading={loadingEstoque}
                    active={initialEstoque}
                    onClick={() => {
                        handleEstoque(!initialEstoque);
                        setInitialEstoque(!initialEstoque);
                    }}
                    titulo="Sem estoque"
                />
                <Toggle
                    active={initalStatus}
                    titulo="Status"
                    loading={loadingStatus}
                    onClick={() => {
                        handleStatus(!initalStatus);
                        setInitialStatus(!initalStatus);
                    }}
                />
            </Flex>
        </HeaderStyles>
    );
}
