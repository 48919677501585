import { Flex, Heading, ButtonSystem } from "aplication-yetz";
import { useRelatorioCompras } from "../../Hook/useRelatorioCompra";

interface HeaderProps {}

export function Header(props: HeaderProps) {
    const { setModalCreate } = useRelatorioCompras();
    return (
        <Flex alignItems="center" justifyContent="space-between" mb="68px">
            <Heading as="h1" fontSize="lg">
                Relatórios de compra
            </Heading>

            <ButtonSystem onClick={() => setModalCreate(true)}>
                GERAR RELATÓRIO
            </ButtonSystem>
        </Flex>
    );
}
