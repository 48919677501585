import { Links } from "aplication-yetz";
import menuImg from "../../assets/sistema/menu-user.svg";
import produtosImg from "../../assets/sistema/menu-produtos.svg";
import estoqueImg from "../../assets/sistema/menuEstoque.svg";
import plataformaImg from "../../assets/sistema/menu-plataforma.svg";
import pedidoImg from "../../assets/sistema/carrinho-menu.svg";
import compraImg from "../../assets/sistema/compra.svg";
import graficoImg from "../../assets/sistema/menu-grafico.svg";
import gestaoImg from "../../assets/sistema/gestao.svg";

export const links: Links[] = [
    {
        label: "Home",
        href: "/usuarios",
        icon: menuImg,
    },
    {
        label: "Produtos",
        href: "/produtos",
        icon: produtosImg,
    },
    {
        label: "Estoque",
        href: "/estoque",
        icon: estoqueImg,
    },
    {
        label: "Estoque",
        href: "/estoque",
        icon: estoqueImg,
    },
    {
        label: "Plataforma",
        href: "/plataforma",
        icon: plataformaImg,
    },
    {
        label: "Pedidos",
        href: "/pedidos",
        icon: pedidoImg,
    },
    {
        label: "Compra",
        href: "/compra",
        icon: compraImg,
    },
    // {
    //     label: "Whitelist",
    //     href: "/whitelist",
    //     icon: gestaoImg,
    // },
    {
        label: "Relatórios",
        href: "/relatorios",
        icon: graficoImg,
    },
    {
        label: "Fornecedor",
        href: "/fornecedor",
        icon: menuImg,
    },
    // {
    //     label: "Relatórios Compras",
    //     href: "/relatorio-compra",
    //     icon: imgRelatorio,
    // },
    {
        label: "Gestão",
        href: "/gestao",
        icon: gestaoImg,
    },
];
