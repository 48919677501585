import { Flex, Heading, Filter, ButtonSystem, Text } from "aplication-yetz";
import { Link } from "react-router-dom";
import { ChangeEvent, useCallback } from "react";
import debounce from "lodash.debounce";
import { api } from "../../services";
import { toast } from "react-toastify";

interface HeaderProps {
    setModal: (active: boolean) => void;
    setFilter: (filter: string) => void;
}

export function Header(props: HeaderProps) {
    const { setModal, setFilter } = props;

    // eslint-disable-next-line
    const debouncedChange = useCallback(
        debounce((value: string) => setFilter(value), 800),
        []
    );

    function handleFilterChange(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();

        const newValue = event.target.value;

        debouncedChange(newValue);
    }

    async function exportar() {
        try {
            const outputFilename = `${Date.now()}.csv`;

            const response: any = await api.get(`relatorio/list-produtos`, {
                responseType: "blob",
            });

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
        }
    }

    return (
        <Flex>
            <Heading as="h2" fontSize="lg">
                Produtos
            </Heading>

            <Filter ml="auto" mr="200px" onChange={handleFilterChange} />

            <Flex alignItems="center" ml="auto" justifyContent="space-between">
                {/* <ButtonSystem mr="16px">
                    <img src={itensImg} alt="" />
                </ButtonSystem> */}

                <Link to="/categorias">
                    <Text
                        className="categorias"
                        color="primary"
                        bold={true}
                        mr="40px"
                    >
                        Categorias
                    </Text>
                </Link>

                <ButtonSystem onClick={() => setModal(true)}>
                    Adicionar produto
                </ButtonSystem>

                <ButtonSystem ml="12px" onClick={exportar}>
                    Exportar
                </ButtonSystem>
            </Flex>
        </Flex>
    );
}
