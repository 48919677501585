import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Heading, Flex, Text, ButtonSystem } from "aplication-yetz";
import {
    Formik,
    Field,
    Form,
    ErrorMessage,
    FormikProps,
    FormikHelpers,
} from "formik";
import { useUser } from "../../Hook/useUser";

import { Input, InputMask } from "../../components/Input";
import { Toggle } from "../../components/Toggle";
import { Select } from "../../components/Select";

import { Usuario, Roles } from "./types";
import { initialValues, addSchema, updateSchema } from "./config";

import closeImg from "../../assets/sistema/fechar-modal.svg";
import { Modal } from "./styles";
import { toast } from "react-toastify";
import { maskCPF } from "../../util/helper";

interface UserProps {
    rest: {
        onClose: (active: boolean) => void;
        user: Usuario;
        modalActive: boolean;
    };
}

export function User(props: UserProps) {
    const { create, update, getRoles } = useUser();
    const { user, modalActive } = props.rest;
    const [roles, setRoles] = useState<Roles[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [initialValuesUser, setInitialValuesUser] =
        useState<Usuario>(initialValues);
    const [validationSchema, setValidationSchema] = useState<any>(addSchema);

    const rolesOptions = roles.map((role) => {
        return {
            label: role.name,
            value: role.id,
        };
    });

    const hasUser = Object.values(user).length ? true : false;

    async function handleSubmit(
        values: Usuario,
        actions: FormikHelpers<Usuario>
    ) {
        setLoading(true);

        const { phone_number, ...rest } = values;

        const dados = {
            ...rest,
            phone_number: "55" + phone_number.replace(/\D/g, ""),
            document: values.document.replace(/\D/g, ""),
        };

        const response = user.id
            ? await update(dados, user.id)
            : await create(dados);

        if (response.status === 201 || response.status === 200) {
            actions.resetForm();
            props.rest.onClose(false);
            toast.success("Sucesso!");
        }

        setLoading(false);
    }

    useEffect(() => {
        async function fetchData() {
            const dados = await getRoles();
            setRoles(dados);
        }
        if (modalActive) {
            fetchData();
        }
        // eslint-disable-next-line
    }, [modalActive]);

    useEffect(() => {
        if (hasUser) {
            setInitialValuesUser(user);
            setValidationSchema(updateSchema);
        } else {
            setInitialValuesUser(initialValues);
            setValidationSchema(addSchema);
        }
    }, [user, hasUser]);

    const rolesActive = useCallback(() => {
        if (hasUser) {
            return rolesOptions.filter((item) => item.label === user.role)[0];
        }

        return null;
    }, [rolesOptions, user, hasUser]);

    return (
        <Modal>
            <Flex justifyContent="space-between" alignItems="center" mb="34px">
                <Heading as="h2" fontSize="mdl">
                    {hasUser ? "Editar Usuário" : "Novo Usuário"}
                </Heading>
                <button
                    onClick={() => {
                        props.rest.onClose(false);
                    }}
                >
                    <img src={closeImg} alt="Fechar" />
                </button>
            </Flex>

            <Formik
                initialValues={initialValuesUser}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {
                    handleSubmit(values, actions);
                }}
            >
                {(props: FormikProps<Usuario>) => {
                    const { setFieldValue, values } = props;
                    const { status } = values;
                    console.log(props);
                    return (
                        <Form>
                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="name"
                                    label="Nome"
                                />
                                <ErrorMessage component="small" name="name" />
                            </div>

                            <div className="container-form">
                                <Field
                                    placeholder="Login"
                                    name="document"
                                    type="tel"
                                    autoComplete="organization"
                                    component={InputMask}
                                    mask="999.999.999-99"
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => {
                                        const document = maskCPF(
                                            e.target.value
                                        );

                                        if (document.length <= 14) {
                                            setFieldValue("document", document);
                                        }
                                    }}
                                    value={values.document}
                                />
                                <ErrorMessage component="small" name="email" />
                            </div>

                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="email"
                                    label="E-mail"
                                />
                                <ErrorMessage component="small" name="email" />
                            </div>

                            <div className="container-form">
                                <Field
                                    component={InputMask}
                                    name="phone_number"
                                    label="Celular"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="phone_number"
                                />
                            </div>

                            <div className="container-form">
                                <Select
                                    initalValue={rolesActive()}
                                    options={rolesOptions}
                                    placeholder="Perfil"
                                    onChange={(values) =>
                                        setFieldValue("role", values.label)
                                    }
                                />

                                <ErrorMessage component="small" name="perfil" />
                            </div>

                            <div className="container-form status">
                                <Flex
                                    justifyContent="space-between"
                                    width="100%"
                                >
                                    <Text color="gray300">Status</Text>
                                    <Toggle
                                        active={status ? true : false}
                                        onClick={() =>
                                            setFieldValue(
                                                "status",
                                                !status ? 1 : 0
                                            )
                                        }
                                    />
                                </Flex>
                            </div>

                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="password"
                                    label="Senha"
                                    type="password"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="password"
                                />
                            </div>

                            <div className="container-form">
                                <Field
                                    component={Input}
                                    name="password_confirmation"
                                    type="password"
                                    label="Confirma Senha"
                                />
                                <ErrorMessage
                                    component="small"
                                    name="password_confirmation"
                                />
                            </div>

                            <ButtonSystem
                                mt="42px"
                                width="210px"
                                loading={loading}
                                heightSpiner={18}
                                widthSpnier={18}
                            >
                                Cadastrar Usuário
                            </ButtonSystem>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}
