import { useState } from "react";
import { Table, Text, TextImg, Flex } from "aplication-yetz";
import { useEstoque, HistorioCarga } from "../../Hook/useEstoque";

import { Modal } from "../Modal";
import { ModalDetalheCarga } from "../Estoque/modalDetalheCarga";
import { BoxExclusao } from "../BoxNotificacao";
import {
    formatCurrentMoney,
    formatDateIso,
    filterHistoricoCarga,
} from "../../util/helper";

import { DowloadCarga } from "./downloadCarga";

import { column } from "./config";
import { Data } from "./types";

import userImg from "../../assets/sistema/usuario.svg";
import anexoImg from "../../assets/sistema/anexo.svg";

import visualizarImg from "../../assets/sistema/olho.svg";
import excluirImg from "../../assets/sistema/excluir.svg";

import { ContainerTable } from "../../components/tables/styles";

export interface HeaderModalDetalheCarga {
    total_voucher: number;
    total_valor: number | string;
    nome: string;
    date: string;
}

interface TableCargasProps {
    filtro: string;
}

export function TableCargas(props: TableCargasProps) {
    const { filtro } = props;
    const {
        historicoCargas,
        excluirCarga,
        loadingExClusaoCarga,
        getHistoricoCargasById,
    } = useEstoque();
    const [modalDetalheCarga, setModalDetalheCarga] = useState<boolean>(false);
    const [modalExclusao, setModalExclusao] = useState<boolean>(false);
    const [cargaId, setCargaId] = useState<number | null>(null);
    const [detalheCargaEstoque, setDetalheCargaEstoque] = useState<
        HistorioCarga[]
    >([]);
    const [dadosCarga, setDadosCarga] = useState<HeaderModalDetalheCarga>(
        {} as HeaderModalDetalheCarga
    );

    const configModal = {
        data: detalheCargaEstoque,
        headerModalDetalheCarga: { ...dadosCarga },
        onClose: () => setModalDetalheCarga(false),
    };

    const confirmaExclusao = {
        img: excluirImg,
        loading: loadingExClusaoCarga,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                excluir esta carga?
            </Text>
        ),
        width: "500px",
        onClick: () => (cargaId ? handleExcluirCarga(cargaId) : null),
        closeModal: () => {
            setModalExclusao(false);
            setCargaId(null);
        },
    };

    const arrFilter = filterHistoricoCarga(historicoCargas, filtro);

    const data: Data[] = arrFilter.map((carga) => {
        const { id, nome, usuario, total_reais, total_qtd, created_at } = carga;

        return {
            nome: <Text> {nome}</Text>,
            usuario: (
                <TextImg className="user" img={userImg} color="black">
                    {usuario}
                </TextImg>
            ),
            id: <Text>{id}</Text>,
            dataHora: <Text>{formatDateIso(created_at)}</Text>,
            voucher: (
                <TextImg className="voucher" img={anexoImg} color="black">
                    {total_qtd}
                </TextImg>
            ),
            valor: <Text> {formatCurrentMoney(parseFloat(total_reais))}</Text>,
            acoes: (
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    width="142px"
                >
                    <DowloadCarga id={id} />
                    <button
                        onClick={() =>
                            open(
                                {
                                    total_voucher: total_qtd,
                                    date: formatDateIso(created_at),
                                    nome,
                                    total_valor: formatCurrentMoney(
                                        parseFloat(total_reais)
                                    ),
                                },
                                id
                            )
                        }
                    >
                        <img src={visualizarImg} alt="" />
                    </button>

                    <button
                        onClick={() => {
                            setModalExclusao(true);
                            setCargaId(id);
                        }}
                    >
                        <img src={excluirImg} alt="" />
                    </button>
                </Flex>
            ),
        };
    });

    async function handleExcluirCarga(id: number) {
        await excluirCarga(id);
        setModalExclusao(false);
        setCargaId(null);
    }

    async function open(dados: HeaderModalDetalheCarga, id: number) {
        const { data } = await getHistoricoCargasById(id);

        setDetalheCargaEstoque(data);
        setDadosCarga({ ...dados });
        setModalDetalheCarga(true);
    }

    return (
        <>
            <ContainerTable>
                <Table columns={column} data={data} />
            </ContainerTable>
            <Modal
                component={ModalDetalheCarga}
                active={modalDetalheCarga}
                rest={configModal}
            />
            <Modal
                component={BoxExclusao}
                active={modalExclusao}
                rest={confirmaExclusao}
            />
        </>
    );
}
