import { Column } from "react-table";
import { Data, FormVariacao, FormTutorial } from "./type";
import * as Yup from "yup";

export const column: Column<Data>[] = [
    {
        Header: "Cod. variação",
        accessor: "cod_variacao",
    },
    {
        Header: "valor em reais",
        accessor: "valor_em_reais",
    },
    {
        Header: "valor em YETZ",
        accessor: "valor_em_yetz",
    },
    {
        Header: "Alerta de estoque",
        accessor: "alerta_de_estoque",
    },
    {
        Header: "Aviso estoque",
        accessor: "status_alerta_estoque",
    },
    {
        Header: "Disponibilidade",
        accessor: "disponibilidade",
    },
    {
        Header: "Ações",
        accessor: "acoes",
    },
];

export const initialValues: FormVariacao = {
    nome: "",
    status: 1,
    valor_yetz: "",
    valor_reais: "",
    alerta_estoque: "",
};

export const Schema = Yup.object().shape({
    nome: Yup.string().required("Campo é obrigatório"),
    status: Yup.number().required("Campo é obrigatório"),
    valor_yetz: Yup.string().required("Campo é obrigatório"),
    valor_reais: Yup.string().required("Campo é obrigatório"),
    alerta_estoque: Yup.string().required("Campo é obrigatório"),
});

export const initialValuesTutorial: FormTutorial = {
    tutorial_observacao: "",
    tutorial_url: "",
    tutorial_passos: "[]",
    external_link: "",
    external_link_label: "",
};

export const SchemaTutorial = Yup.object().shape({
    tutorial_observacao: Yup.string().nullable(true),
    tutorial_url: Yup.string().nullable(true),
    tutorial_passos: Yup.string().required("Campo é obrigatório"),
    external_link: Yup.string(),
    external_link_label: Yup.string(),
});
