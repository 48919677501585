import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Error } from "../../components/ValePresente/error";
// import { Encerrada } from "../../components/ValePresente/encerrada";
import { Footer } from "../../components/Footer";
import { Bloqueado } from "../../components/ValePresente/bloqueado";

import { Modal } from "../../components/Modal";
import { ModalVideo } from "../../components/PassoAPasso/video";
import { ModalTutorial } from "../../components/PassoAPasso/modal";

// import { MultiplosVoucher } from "../../components/MultiplosVoucher";
import { MultiplosVoucherGrandCru } from "../../components/MultiplosVoucher/grandCru";
import { api } from "../../services";
import { ExpiradoPersonalite } from "../../components/ValePresente/expiradoPersonnalite";
import { formatDateUser } from "../../util/helper";
import { Expirado } from "../../components/ValePresente/expirado";
import { useVoucherConfig } from "../../Hook/useVoucherConfig";

export interface ResponseVoucher {
    success: boolean;
    error: string;
    voucher?: Voucher[];
    status: string;
    // expires_at: string | null;
    produto_id?: number;
    // voucher?: Voucher;
}

export interface Voucher {
    date: string | null;
    code: string | null;
    password: string | null;
    descricao: string | null;
    foto_capa: string | null;
    nome: string | null;
    selo: string | null;
    tutorial_observacao: string | null;
    tutorial_passos: string | null;
    tutorial_url: string | null;
    validade: string | null;
    valor: string | null;
    slug: string | null;
    template?: string | null;
    informacoes_importantes: string;
    detalhes: string;
    codes?: any;
    expires_at: string | null | any;
    variacao?: any;
    cupom?: number;
}

export function PageMultiplosVoucherGrandCru() {
    const history = useHistory();
    const { setHashCode } = useVoucherConfig();
    const [voucher, setVoucher] = useState<ResponseVoucher>(
        {} as ResponseVoucher
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [modalVideo, setModalVideo] = useState<boolean>(false);
    const [modalTutorial, setModalTutorial] = useState<boolean>(false);
    const personalite = [116, 123, 124];

    const hash_code = history.location.pathname.replace("/g/", "");

    const configProduto = {
        title: "Produtos",
        video: voucher?.voucher ? voucher?.voucher[0].tutorial_url : "",
        tutorial: voucher?.voucher ? voucher?.voucher[0].tutorial_passos : "[]",
        img: voucher?.voucher ? voucher?.voucher[0].foto_capa : "",
        tutorialObservacao: voucher?.voucher
            ? voucher?.voucher[0].tutorial_observacao
            : "",
        onClose: () => {
            setModalTutorial(false);
            setModalVideo(false);
        },
        openModalVideo: () => {
            setModalTutorial(false);
            setModalVideo(true);
        },
        dados: voucher?.voucher ? voucher?.voucher : {},
    };

    const configVideo = {
        video: voucher?.voucher ? voucher?.voucher[0].tutorial_url : "",
        modalVideo: modalVideo,
        closeModal: () => {
            setModalTutorial(true);
            setModalVideo(false);
        },
        // video: "81db6ab30a",
    };

    function clickOutModal(e: React.MouseEvent<HTMLElement>) {
        const element = (e.target as HTMLElement).dataset;

        if (element?.closemodal) {
            setModalTutorial(false);
            setModalVideo(false);
        }
    }

    useEffect(() => {
        async function checkToken() {
            setLoading(true);
            try {
                const { data } = await api.post<ResponseVoucher>(
                    `/pedido/check-url`,
                    {
                        hash_code,
                    }
                );

                setHashCode(hash_code);

                // if (data.voucher?.[0].template === "LINK") {
                //     if (data.voucher[0]?.code) {
                //         window.open(data.voucher[0]?.code, "_self");
                //         // history.push("/");
                //         return;
                //     }
                // }

                const regexHasUrl =
                    /(http|https):\/\/(www\.)?[a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

                if (
                    regexHasUrl.test(
                        data.voucher ? data.voucher[0].code || "" : ""
                    )
                ) {
                    window.location.href = data.voucher
                        ? data.voucher[0].code || ""
                        : "";
                } else {
                    setVoucher(data);
                }
            } catch (error: any) {
                console.log(error);
            }
            setLoading(false);
        }

        checkToken();
    }, [hash_code, history, setHashCode]);

    return (
        <>
            {voucher.success === false ? (
                <>
                    {voucher?.error === "Link not found" && <Error />}
                    {voucher.status === "BLOQUEADO" && <Bloqueado />}

                    {voucher?.error === "Link expirado" &&
                    personalite.includes(
                        voucher?.voucher
                            ? (voucher?.voucher[0].variacao as number)
                            : 0
                    ) ? (
                        <ExpiradoPersonalite
                            date={
                                voucher?.voucher
                                    ? formatDateUser(
                                          voucher.voucher[0].expires_at
                                      )
                                    : ""
                            }
                        />
                    ) : (
                        voucher?.error === "Link expirado" && (
                            <Expirado
                                date={
                                    voucher?.voucher
                                        ? formatDateUser(
                                              voucher?.voucher[0].expires_at
                                          )
                                        : ""
                                }
                            />
                        )
                    )}

                    <Footer />
                </>
            ) : (
                <MultiplosVoucherGrandCru
                    setModalTutorial={setModalTutorial}
                    voucher={voucher}
                    loading={loading}
                />
            )}

            <Modal
                component={ModalTutorial}
                active={modalTutorial}
                rest={configProduto}
                onClick={clickOutModal}
                maxWidthMobile={true}
            />
            <Modal
                onClick={clickOutModal}
                component={ModalVideo}
                active={modalVideo}
                rest={configVideo}
            />
        </>
    );
}
