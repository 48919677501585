import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { api } from "../../services";
import { LocalStoreProps, User } from "../../Hook/useAuthentication";
import { Loader } from "../../components/Loader";

export function LoginSuperAdm() {
    const history = useHistory();
    const hash_code = history.location.pathname.replace("/token/", "");

    useEffect(() => {
        async function login() {
            try {
                const { data } = await api.get<User>("usuario/me", {
                    headers: {
                        Authorization: `Bearer ${hash_code}`,
                    },
                });

                const { name, id, phone_number } = data;
                const user: LocalStoreProps = {
                    name,
                    token: hash_code,
                    nivel: "",
                    id,
                    phone_number,
                };

                localStorage.setItem("user", JSON.stringify(user));
                history.push("/sistema");

                console.log(data);
            } catch (error) {
                history.push("/");
                console.log(error);
            }
        }
        login();
    }, [hash_code, history]);

    return (
        <div className="loader">
            <Loader width={300} height={300} />
        </div>
    );
}
