import { useState } from "react";
import { Flex, TextImg, Text } from "aplication-yetz";
import { toast } from "react-toastify";
import { useDetalhePedido } from "../../Hook/useDetalhePedidoPlataforma";
import { Goback } from "../../components/Goback";
import { api } from "../../services/";
import imgExtrair from "../../assets/sistema/exportar.svg";
import imgCancelar from "../../assets/sistema/fechar-simples.svg";
import imgCadeado from "../../assets/sistema/cadeado.svg";
import imgAlerta from "../../assets/sistema/alerta.svg";
import { Modal } from "../../components/Modal";
import { BoxExclusao } from "../../components/BoxNotificacao";

export function Header() {
    const {
        handleBloquear,
        loadingBloquear,
        handleCancelar,
        loadingCancelar,
        detalhePedido,
    } = useDetalhePedido();
    const [modalBloquear, setModalBloquear] = useState(false);
    const [modalCancelar, setModalCancelar] = useState(false);

    const bloquear = {
        img: imgCadeado,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                {detalhePedido.status === "BLOQUEADO"
                    ? "desbloquear"
                    : "bloquear"}
                este pedido?
            </Text>
        ),
        width: "500px",
        onClick: async () => {
            const response = await handleBloquear(
                detalhePedido.id,
                detalhePedido.status
            );
            response.status === 200 && setModalBloquear(false);
        },
        closeModal: () => setModalBloquear(false),
        loading: loadingBloquear,
    };

    const cancelar = {
        img: imgAlerta,
        title: (
            <Text as="p" fontWeight="600" fontSize="mdl" color="black">
                Tem certeza que deseja <br />
                cancelar este pedido?
            </Text>
        ),
        width: "500px",
        onClick: async () => {
            const response = await handleCancelar(detalhePedido.id);
            response.status === 200 && setModalCancelar(false);
        },
        closeModal: () => setModalCancelar(false),
        loading: loadingCancelar,
    };

    async function exportar() {
        try {
            const outputFilename = `${Date.now()}.csv`;

            const response: any = await api.get(
                `pedido/${detalhePedido.id}/export`,
                {
                    responseType: "blob",
                }
            );

            var data = new Blob([response.data]);
            var csvURL = window.URL.createObjectURL(data);
            var tempLink;
            tempLink = document.createElement("a");
            tempLink.href = csvURL;
            tempLink.setAttribute("download", outputFilename);
            tempLink.click();

            toast.success("Download realizado com sucesso!");
        } catch (error: any) {
            console.log(error.response);

            toast.error("Erro ao exportar arquivo");
        }
    }

    return (
        <>
            <Flex
                justifyContent="space-between"
                className="header-detalhe-pedido"
            >
                <Goback title="Detalhes do Pedido" />

                <Flex
                    justifyContent="space-around"
                    alignItems="center"
                    width="462px"
                >
                    <button onClick={() => exportar()}>
                        <TextImg color="primary" img={imgExtrair}>
                            Exportar
                        </TextImg>
                    </button>
                    {detalhePedido?.status !== "CANCELADO" ? (
                        <button
                            onClick={() => setModalCancelar(!modalCancelar)}
                        >
                            <TextImg color="primary" img={imgCancelar}>
                                Cancelar
                            </TextImg>
                        </button>
                    ) : (
                        <></>
                    )}

                    <button onClick={() => setModalBloquear(!modalBloquear)}>
                        <TextImg color="primary" img={imgCadeado}>
                            Bloquear Todos
                        </TextImg>
                    </button>
                </Flex>
            </Flex>

            <Modal
                component={BoxExclusao}
                active={modalBloquear}
                rest={bloquear}
            />
            <Modal
                component={BoxExclusao}
                active={modalCancelar}
                rest={cancelar}
            />
        </>
    );
}
