import { MouseEvent, MutableRefObject, useRef } from "react";
import { Link } from "react-router-dom";
import { Text, ButtonOpenTable } from "aplication-yetz";
import { Column, Cell } from "react-table";
import {
    useTable,
    useFlexLayout,
    useResizeColumns,
    usePagination,
} from "react-table";
import { v4 as uuidv4 } from "uuid";

import cardEditarImg from "../../assets/sistema/card-editar.svg";
import setaBaixoImg from "../../assets/sistema/seta-baixo.svg";

import { HeaderDetalheProduto } from "../DetalheProduto";
import { Data } from "../../components/TabelaProdutos/types";

import { handleOpen } from "../../util/helper";

import { Container } from "./styles";
import { Variacao as TypeVariacao } from "../../Hook/useProduto";

export type TableProps<T extends object> = {
    data: Array<T>;
    columns: Column<T>[];
    index: number;
    pageSize?: number;
};

export interface handleOpenProps {
    arrayRefs: MutableRefObject<HTMLDivElement[]>;
    refAtaul: number;
    id: any;
}

export const Variacao = (props: handleOpenProps) => {
    const { arrayRefs, refAtaul, id } = props;

    return (
        <div className="icones-produto">
            <Link
                to={{
                    pathname: `/produtos/detalhe/${id}`,
                    state: { id },
                }}
            >
                <img src={cardEditarImg} alt="" />
            </Link>
            <ButtonOpenTable
                imgLeft=""
                imgRight={setaBaixoImg}
                onClick={(event: MouseEvent<Element>) =>
                    handleOpen(arrayRefs, event, refAtaul)
                }
                active={false}
                fontSize="xs"
                color="primary"
            >
                Variações
            </ButtonOpenTable>
        </div>
    );
};

export function Table(props: TableProps<Data>) {
    const { columns, data, index } = props;

    const length = data.length;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,

        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: props.pageSize ? props.pageSize : 10,
            },
        },
        useFlexLayout,
        useResizeColumns,
        usePagination
    );
    const refItens = useRef<HTMLDivElement[]>([]);

    const itens = data.map((item) => item.cod_produto);

    const variacao = data.reduce((acc, { variacao }) => {
        if (variacao) {
            acc.push(...variacao);
        }
        return acc;
    }, [] as TypeVariacao[]);

    return (
        <Container>
            <div {...getTableProps()} className="table">
                <div>
                    {headerGroups.map((headerGroup) => (
                        <div
                            {...headerGroup.getHeaderGroupProps()}
                            className={`tr header ${
                                index !== 0 ? "hide-header" : ""
                            }`}
                        >
                            {headerGroup.headers.map((column) => (
                                <div
                                    {...column.getHeaderProps()}
                                    className="th"
                                >
                                    {column.render("Header")}
                                    <div
                                        {...column.getResizerProps()}
                                        className={`resizer ${
                                            column.isResizing
                                                ? "isResizing"
                                                : ""
                                        }`}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}
                </div>

                {itens.map((item, key) => {
                    return (
                        <div
                            {...getTableBodyProps()}
                            ref={(el: HTMLDivElement) => {
                                refItens.current[key] = el;
                            }}
                            className="container-tables"
                        >
                            {page.map(function (row, key) {
                                prepareRow(row);
                                return (
                                    <div
                                        {...row.getRowProps()}
                                        className="tr body"
                                        key={uuidv4()}
                                    >
                                        {row.cells.map((cell: Cell<Data>) => {
                                            const idCell = uuidv4();
                                            return (
                                                <div
                                                    {...cell.getCellProps()}
                                                    className="td"
                                                    key={idCell}
                                                >
                                                    {Array.isArray(
                                                        cell.value
                                                    ) && variacao.length ? (
                                                        <Variacao
                                                            arrayRefs={refItens}
                                                            refAtaul={key}
                                                            id={row.original.id}
                                                        />
                                                    ) : (
                                                        <Text>
                                                            <>
                                                                {cell.render(
                                                                    "Cell"
                                                                )}
                                                            </>
                                                        </Text>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}

                            <div className="conteudo">
                                <HeaderDetalheProduto itens={variacao} />
                            </div>
                        </div>
                    );
                })}

                {length > 10 ? (
                    <div className="pagination">
                        <div className="pre">
                            <button
                                onClick={() => gotoPage(0)}
                                disabled={!canPreviousPage}
                            >
                                {"<<"}
                            </button>{" "}
                            <button
                                onClick={() => previousPage()}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </button>{" "}
                        </div>
                        <div className="next">
                            <button
                                onClick={() => nextPage()}
                                disabled={!canNextPage}
                            >
                                {">"}
                            </button>{" "}
                            <button
                                onClick={() => gotoPage(pageCount - 1)}
                                disabled={!canNextPage}
                            >
                                {">>"}
                            </button>{" "}
                        </div>
                        <span>
                            Page{" "}
                            <strong>
                                {pageIndex ? pageIndex + 1 : 0} de{" "}
                                {pageOptions ? pageOptions.length : 0}
                                {"  "}
                            </strong>
                            {"  "}
                        </span>
                        <span>| Vá para pagina: </span>{" "}
                        <div className="container-input">
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value
                                        ? Number(e.target.value) - 1
                                        : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: "100px" }}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        </Container>
    );
}
