import React from "react";
import { Link } from "react-router-dom";
import { TextImg } from "aplication-yetz";
import { Produto } from "../../Hook/useProduto";

import { Table } from "./table";
import { Column } from "react-table";

import imgPc from "../../assets/sistema/pc.svg";
import tutorialImg from "../../assets/sistema/tutorial.svg";

import { Data } from "./types";

import { Acordion } from "./styles";

interface AppProps {
    produto: Produto[];
}

function App(props: AppProps) {
    const { produto } = props;

    const columns = React.useMemo<Column<Data>[]>(
        () => [
            {
                Header: "Imagem",
                accessor: "imagem",
            },
            {
                Header: "Cod. Produto",
                accessor: "cod_produto",
            },
            {
                Header: "Tipo de  Produto",
                accessor: "tipo_de_produto",
            },
            {
                Header: "Nome",
                accessor: "nome",
            },
            {
                Header: "Categoria",
                accessor: "categoria",
            },
            {
                Header: "Tutorial",
                accessor: "tutorial",
            },
            {
                Header: "Ações",
                accessor: "variacao",
            },
        ],
        []
    );

    const data: Data[] = produto.map((item) => {
        const { foto_capa, id, tipo, nome, url_tutorial, variacoes } = item;

        return {
            id,
            imagem: (
                <Link
                    to={{
                        pathname: `/produtos/detalhe/${id}`,
                        state: { id },
                    }}
                >
                    <img src={foto_capa} alt="" width="75px" height="50px" />
                </Link>
            ),
            cod_produto: id,
            tipo_de_produto: <TextImg img={imgPc}>{tipo}</TextImg>,
            nome,
            categoria: item.categorias
                ?.map((item) => item.categoria.nome)
                .join(", "),
            tutorial: <TextImg img={tutorialImg}>{url_tutorial}</TextImg>,
            variacao: variacoes,
        };
    });

    // console.log(data);

    return (
        <Acordion>
            {data.map((item, key, arr) => (
                <Table
                    columns={columns}
                    data={[item]}
                    index={key}
                    key={item.cod_produto}
                />
            ))}
        </Acordion>
    );
}

export default App;
