import { Heading, Filter, Flex, ButtonSystem, TextImg } from "aplication-yetz";
import { useHistory } from "react-router-dom";
import { ChangeEvent } from "react";
import { useCompras } from "../../Hook/useCompras";

interface HeaderProps {
    setFilter: (filter: string) => void;
}

export function Header(props: HeaderProps) {
    const { setFilter } = props;
    const history = useHistory();
    const { modal, setModal } = useCompras();

    return (
        <Flex
            justifyContent="space-between"
            alignItems="flex-end"
            pr="55px"
            mb="78px"
        >
            <Heading as="h2" fontSize="lg">
                Compras
            </Heading>

            <Filter
                onClick={() => setModal(!modal)}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFilter(e.target.value)
                }
            />

            <Flex className="container-btns">
                <button
                    onClick={() => {
                        history.push("relatorio-compra");
                    }}
                >
                    <TextImg color="primary" img="" bold>
                        RELATÓRIOS DE COMPRAS
                    </TextImg>
                </button>

                <ButtonSystem
                    ml="42px"
                    onClick={() => history.push("/compra/adicionar")}
                >
                    ADICIONAR COMPRA
                </ButtonSystem>
            </Flex>
        </Flex>
    );
}
