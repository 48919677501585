import { Flex, Filter, Heading, ButtonSystem } from "aplication-yetz";
import { ChangeEvent } from "react";

interface HeaderProps {
    setModal: (active: boolean) => void;
    setFiltro: (value: string) => void;
}

export function Header(props: HeaderProps) {
    const { setModal, setFiltro } = props;
    return (
        <Flex alignItems="center" justifyContent="space-between" mb="68px">
            <Heading as="h1" fontSize="lg">
                Usuário
            </Heading>

            <Filter
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFiltro(e.target.value)
                }
            />

            <ButtonSystem onClick={() => setModal(true)}>
                adicionar usuário
            </ButtonSystem>
        </Flex>
    );
}
